<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Financeiro</div>
    <div class="text-500 mb-5">Descritivo financeiro do tratamento do paciente.</div>
    <div class="surface-card grid grid-nogutter border-round shadow-2">
      <div class="col-12 flex p-2 surface-100 border-round-top">
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-600 block">Título</span>
          <span class="text-900 font-medium block mt-2">{{ schedule.title }}</span>
        </div>
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-600 block">Paciente</span>
          <span class="text-900 font-medium block mt-2">{{ schedule.contact.name }}</span>
        </div>
        <Divider align="center" layout="vertical" class="h-full  mx-0 lg:mx-3 surface-border"></Divider>
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-600 block">Data Atendimento</span>
          <span class="text-900 font-medium block mt-2">{{ schedule.startDate }}</span>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="surface-card p-2 shadow-2 border-round p-fluid">
        <div class="flex flex-column mb-3 lg:flex-row justify-content-between align-items-center"
             v-for="(schedulerProcedure) of schedulerProcedures" :key="schedulerProcedure.procedure.id"
        >
          <div class="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
            <img src="@/assets/grafico-de-saude.png" class="w-8rem h-8rem flex-shrink-0 mb-3 bg-blue-50"/>

            <div class="flex flex-column my-auto text-center md:text-left">
              <span class="text-900 font-medium mb-3 mt-3 lg:mt-0">{{ schedulerProcedure.procedure.name }}</span>
              <span class="text-600 text-sm mb-3">Valor | {{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(schedulerProcedure.procedure.price)}} (Unidade)</span>
              <div class="text-900 w-full md:w-10">
                <InputNumber inputId="percent"
                             v-model="schedulerProcedure.amount"
                             showButtons
                             :min="1" :max="100" @focusout="calcTotalProcedimentos($event)"/>
              </div>
            </div>
          </div>
          <div class="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style="border-radius: 2.5rem">
            <div v-ripple tabindex="0"
                 class="p-2 cursor-pointer w-15rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-primary text-primary transition-duration-150 p-ripple">
              Total <span class="font-light">| <b>{{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(calcProductUnit(schedulerProcedure))}}</b></span>
            </div>
          </div>
        </div>
        <Divider class="w-full block lg:hidden surface-border"></Divider>
      </div>
    </div>
    <div class="py-2 mt-3 border-bottom-1 surface-border">
      <div class="mb-3 text-right">
        <InputNumber
            v-model="schedule.discount" suffix="%"
            :min="0" :max="100"
            placeholder="Desconto" class="w-min"/>
        <Button label="Aplicar" class="p-button-help" :disabled="!schedule.discount"
                @click="calcTotalProcedimentos"></Button>
      </div>
    </div>
    <div class="col-12 p-0 flex border-top-1 surface-border">
            <span tabindex="0"
                  class="py-4 text-right right-100 flex flex-column md:flex-row text-right mr-5 justify-content-end align-items-end text-primary transition-duration-150 w-full"
                  style="border-bottom-right-radius: 6px;">
              <b>Total: {{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(totalProcedimentos)}}</b>
            </span>
    </div>
  </div>
</template>
<script>
import ScheduleProcedureService from "@/service/ScheduleProcedureService";

export default {
  name: 'financeiro-tab',
  created() {
    this.schedulerProceduresService = new ScheduleProcedureService();
  },
  props: {
    scheduleObject: {
      type: Object
    }
  },
  data() {
    return {
      schedule: this.scheduleObject,
      totalProcedimentos: 0.0,
      schedulerProcedures: [],
      schedulerProceduresService: null
    }
  },
  async mounted() {
    await this.findBySchedulerProcedureByScheduler();
    await this.calcTotalProcedimentos();
  },
  methods: {
    async findBySchedulerProcedureByScheduler() {
      const response = await this.schedulerProceduresService.findBySchedulerId(this.schedule.id);
      if(response.status === 200) {
        this.schedulerProcedures = response.data;
      }
    },

    async calcTotalProcedimentos() {
      this.totalProcedimentos = 0;
      for (const schedulerProcedures of this.schedulerProcedures) {
        this.totalProcedimentos += (schedulerProcedures.procedure.price * schedulerProcedures.amount);
      }
      if(this.schedule.discount) {
        this.totalProcedimentos -= (this.totalProcedimentos * this.schedule.discount) / 100;
      }
    },

    calcProductUnit(schedulerProcedure) {
      return schedulerProcedure.procedure.price * schedulerProcedure.amount;
    },

    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
  }
}
</script>
<style scoped lang="scss">

.p-tabview p {
  line-height: 1.5;
  margin: 0;
}
</style>
